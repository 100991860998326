import './index.scss';
import * as serviceWorker from './serviceWorker';

function getDownload() {
  let u = navigator.userAgent;
  // let isAndroid = u.indexOf('Android') > -1 || u.indexOf('Adr') > -1; // android终端
  let isiOS = !!u.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/); // ios终端
  let url = 'http://app.mi.com/details?id=com.xiaomi.smarthome';
  if (isiOS) {
    url =
      'https://itunes.apple.com/cn/app/米家-精品商城-智能生活/id957323480?mt=8​';
  }
  url.replace('\xe2\x80\x8b', '');
  window.location.href = url;
}

const button = document.getElementById('download');
button?.addEventListener('click', getDownload);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
